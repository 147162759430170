// src/ui/pages/TasksContainer.js
import React, { useEffect, useState } from 'react';
import lessonsHandler from '../../data/LessonsHandler';
import TasksPresenter from './TasksPresenter';
import { RestClient, config } from '../../data/RestClient';

const TasksContainer = () => {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchTasks = async () => {
    setLoading(true);
    try {
      const fetchedTasks = await lessonsHandler.getTasks();
      const progress = RestClient.get(config.USER_DETAIL_URL)
      console.log(progress.data)
      setTasks(fetchedTasks);
    } catch (error) {
      console.error('Error fetching tasks:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTasks();
  }, []);

  return (
    <div>
      {loading ? <p>Loading tasks...</p> : <TasksPresenter tasks={tasks} />}
    </div>
  );
};

export default TasksContainer;
