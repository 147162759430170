import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import NavigationMenu from '../../NavigationMenu';
import ChatContainer from './ChatContainer';
import LessonsContainer from './LessonsContainer';
import TasksContainer from './TasksContainer';
import useMainPresenter from './MainPresenter';
import Modal from '../components/Modal';
import { useMenu } from '../../MenuContext';
import TaskDetailsContainer from './TaskDetailsContainer';
import './MainContainer.css';

const MainContainer = () => {
  const navigate = useNavigate();
  const { showMenu } = useMenu();
  const {
    loading,
    showCongratsModal,
    setShowCongratsModal,
    handleCloseCongratsModal,
  } = useMainPresenter();

  const handleNavigation = (page) => {
    navigate(`/app/${page}`);
  };

  const handleModalClose = () => {
    setShowCongratsModal(false);
    handleCloseCongratsModal();
  };

  if (loading) return <p>Loading...</p>;

  return (
    <div className="main-container">
      {showCongratsModal && (
        <Modal
          message="Congrats! You are doing great on your journey!"
          onClose={handleModalClose}
        />
      )}

      {showMenu && (
        <NavigationMenu activePage="chat" setActivePage={handleNavigation} />
      )}

      <div className="content">
        <Routes>
          <Route path="chat" element={<ChatContainer />} />
          <Route path="lessons" element={<LessonsContainer />} />
          <Route path="tasks" element={<TasksContainer />} />
          <Route path="/" element={<ChatContainer />} />
          <Route path="task-details" element={<TaskDetailsContainer />} />
        </Routes>
      </div>
    </div>
  );
};

export default MainContainer;
