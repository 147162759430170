import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Launcher.css';

const isValidToken = () => {
  const token = localStorage.getItem('authToken');
  return token !== null && token.trim() !== '';
};

const Launcher = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isValidToken()) {
        navigate('/app/chat');
      } else {
        navigate('/login');
      }
    }, 3000); // 3 seconds before checking token and redirecting

    return () => clearTimeout(timer); // Cleanup timeout
  }, [navigate]);

  return (
    <div className="launcher-container">
      <h1 className="launcher-title">613problems</h1>
    </div>
  );
};

export default Launcher;
