// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background: linear-gradient(135deg, #4a90e2, #9013fe); /* Background gradient */
  margin: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.launcher-title {
  font-family: 'Orbitron', sans-serif;
  font-size: 5rem;
  font-weight: bold;
  color: white;
  background: none; /* Remove any background */
}

.launcher-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full screen height */
  width: 100vw; /* Full screen width */
  margin: 0; /* Reset any inherited margins */
  padding: 0;
}

`, "",{"version":3,"sources":["webpack://./src/ui/entry/Launcher.css"],"names":[],"mappings":"AAAA;EACE,qDAAqD,EAAE,wBAAwB;EAC/E,SAAS;EACT,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,mCAAmC;EACnC,eAAe;EACf,iBAAiB;EACjB,YAAY;EACZ,gBAAgB,EAAE,0BAA0B;AAC9C;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,aAAa,EAAE,uBAAuB;EACtC,YAAY,EAAE,sBAAsB;EACpC,SAAS,EAAE,gCAAgC;EAC3C,UAAU;AACZ","sourcesContent":["body {\n  background: linear-gradient(135deg, #4a90e2, #9013fe); /* Background gradient */\n  margin: 0;\n  height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.launcher-title {\n  font-family: 'Orbitron', sans-serif;\n  font-size: 5rem;\n  font-weight: bold;\n  color: white;\n  background: none; /* Remove any background */\n}\n\n.launcher-container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  height: 100vh; /* Full screen height */\n  width: 100vw; /* Full screen width */\n  margin: 0; /* Reset any inherited margins */\n  padding: 0;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
