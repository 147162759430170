// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body {
  margin: 0;
  padding: 0;
  width: 100%; /* Ensure full width */
  height: 100%; /* Ensure full height */
  box-sizing: border-box; /* Include padding and border in the box model */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100%; /* Full width for React root */
  height: 100%; /* Full height for React root */
  display: flex;
  flex-direction: column;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,WAAW,EAAE,sBAAsB;EACnC,YAAY,EAAE,uBAAuB;EACrC,sBAAsB,EAAE,gDAAgD;EACxE;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,WAAW,EAAE,8BAA8B;EAC3C,YAAY,EAAE,+BAA+B;EAC7C,aAAa;EACb,sBAAsB;AACxB","sourcesContent":["html, body {\n  margin: 0;\n  padding: 0;\n  width: 100%; /* Ensure full width */\n  height: 100%; /* Ensure full height */\n  box-sizing: border-box; /* Include padding and border in the box model */\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n#root {\n  width: 100%; /* Full width for React root */\n  height: 100%; /* Full height for React root */\n  display: flex;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
