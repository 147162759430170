// QuestionHandler.js
import axios from 'axios';
import { RestClient, config } from '../data/RestClient';

class QuestionHandler {
  static async sendQuestion(lessonId, question, currentPayloadBlockId, context) {
    try {
      // Construct the URL with the lesson ID
      const url = `${config.GET_EXPLANATION_URL_V2}${lessonId}/`;

      // Prepare the POST data
      const postData = {
        message: question,
        current_payload_block_id: currentPayloadBlockId,
      };

      // API call to send the question
      const response = await axios.post(url, postData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
      });

      // Extract the structured message from the response
      const structuredMessage = response.data.message;

      // Determine the block_type based on the message type
      let responseMessage = {};
      if (structuredMessage.type === 'plaintext') {
        responseMessage = {
          block_type: 'text',
          text: structuredMessage.text,
        };
      } else if (structuredMessage.type === 'code_example') {
        responseMessage = {
          block_type: 'code_example',
          text: structuredMessage.text,
          code_example: structuredMessage.code_example,
        };
      } else {
        // Handle unexpected types if necessary
        responseMessage = {
          block_type: 'text',
          text: 'Received an unsupported message type.',
        };
      }

      // Return both the user's question and the response message
      return [
        { block_type: 'user_question', text: question },
        responseMessage,
      ];
    } catch (error) {
      console.error('Error fetching explanation:', error);
      throw new Error('Failed to fetch explanation.');
    }
  }
}

export default QuestionHandler;
