import React from 'react';
import './Login.css';

const LoginPresenter = ({ username, password, onUsernameChange, onPasswordChange, onLogin, isLoading }) => {
  return (
    <div className="login-container">
      <h2>Login</h2>
      <input
        type="text"
        placeholder="Username"
        value={username}
        onChange={onUsernameChange}
        className="input-field"
        disabled={isLoading} // Disable inputs during loading
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={onPasswordChange}
        className="input-field"
        disabled={isLoading} // Disable inputs during loading
      />
      <button onClick={onLogin} className="login-button" disabled={isLoading}>
        {isLoading ? (
          <span className="loading-spinner" />
        ) : (
          'Login'
        )}
      </button>
    </div>
  );
};

export default LoginPresenter;
