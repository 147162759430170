// src/ui/pages/LessonsPresenter.js
import React from 'react';
import './Lessons.css';

const LessonsPresenter = ({ lessons, onLessonClick }) => {
  if (!lessons || lessons.length === 0) {
    return <p>No lessons available.</p>;
  }

  return (
    <div className="lessons-container">
      {lessons.map((lesson) => (
        <div
          key={lesson.id}
          className={`lesson-card ${lesson.completed ? 'completed' : ''}`}
          onClick={() => onLessonClick(lesson)}
        >
          <div className="lesson-content">
            <h3>{lesson.title}</h3>
            <p>{lesson.topic}</p>
          </div>
          {lesson.completed && <span className="tick">✅</span>}
        </div>
      ))}
    </div>
  );
};

export default LessonsPresenter;
