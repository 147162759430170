// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navigation-menu {
    width: 100%; /* Full width */
    display: flex;
    justify-content: space-evenly; /* Evenly space buttons */
    background: linear-gradient(135deg, #6a11cb, #2575fc); /* Gradient background */
    padding: 15px 0; /* Vertical padding */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 0; /* Remove any margin */
}

.menu-item {
    flex: 1 1; /* Allow buttons to stretch evenly */
    text-align: center;
    font-size: 1.4rem; /* Larger text for better readability */
    font-weight: bold; /* Make the text stand out */
    color: white;
    gap: 10px;
    padding: 10px 0; /* Vertical padding inside buttons */
    margin: 0 8px; /* Add margin for space between buttons */
    background-color: transparent; /* Transparent by default */
    border-radius: 8px; /* Rounded corners for hover effect */
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s, box-shadow 0.3s; /* Smooth transitions */
}

.menu-item:hover {
    background-color: rgba(255, 255, 255, 0.15); /* Subtle hover background */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Slight shadow on hover */
    transform: scale(1.05); /* Slightly enlarge the button on hover */
}
`, "",{"version":3,"sources":["webpack://./src/NavigationMenu.css"],"names":[],"mappings":"AAAA;IACI,WAAW,EAAE,eAAe;IAC5B,aAAa;IACb,6BAA6B,EAAE,yBAAyB;IACxD,qDAAqD,EAAE,wBAAwB;IAC/E,eAAe,EAAE,qBAAqB;IACtC,wCAAwC;IACxC,SAAS,EAAE,sBAAsB;AACrC;;AAEA;IACI,SAAO,EAAE,oCAAoC;IAC7C,kBAAkB;IAClB,iBAAiB,EAAE,uCAAuC;IAC1D,iBAAiB,EAAE,4BAA4B;IAC/C,YAAY;IACZ,SAAS;IACT,eAAe,EAAE,oCAAoC;IACrD,aAAa,EAAE,yCAAyC;IACxD,6BAA6B,EAAE,2BAA2B;IAC1D,kBAAkB,EAAE,qCAAqC;IACzD,eAAe;IACf,kEAAkE,EAAE,uBAAuB;AAC/F;;AAEA;IACI,2CAA2C,EAAE,4BAA4B;IACzE,wCAAwC,EAAE,2BAA2B;IACrE,sBAAsB,EAAE,yCAAyC;AACrE","sourcesContent":[".navigation-menu {\n    width: 100%; /* Full width */\n    display: flex;\n    justify-content: space-evenly; /* Evenly space buttons */\n    background: linear-gradient(135deg, #6a11cb, #2575fc); /* Gradient background */\n    padding: 15px 0; /* Vertical padding */\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n    margin: 0; /* Remove any margin */\n}\n\n.menu-item {\n    flex: 1; /* Allow buttons to stretch evenly */\n    text-align: center;\n    font-size: 1.4rem; /* Larger text for better readability */\n    font-weight: bold; /* Make the text stand out */\n    color: white;\n    gap: 10px;\n    padding: 10px 0; /* Vertical padding inside buttons */\n    margin: 0 8px; /* Add margin for space between buttons */\n    background-color: transparent; /* Transparent by default */\n    border-radius: 8px; /* Rounded corners for hover effect */\n    cursor: pointer;\n    transition: background-color 0.3s, transform 0.2s, box-shadow 0.3s; /* Smooth transitions */\n}\n\n.menu-item:hover {\n    background-color: rgba(255, 255, 255, 0.15); /* Subtle hover background */\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Slight shadow on hover */\n    transform: scale(1.05); /* Slightly enlarge the button on hover */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
