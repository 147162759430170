// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/ui/pages/Tasks.css */

.tasks-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
}

.task-card {
  width: 80%;
  max-width: 1200px;
  min-width: 600px;
  background-color: #f5f5f5;
  border-radius: 12px;
  padding: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  cursor: pointer;
}

.task-card:hover {
  transform: translateY(-5px);
}

.task-topic {
  font-size: 1.2rem;
  color: #6a11cb;
  margin-bottom: 8px;
}

.task-description {
  color: #555;
}
`, "",{"version":3,"sources":["webpack://./src/ui/pages/Tasks.css"],"names":[],"mappings":"AAAA,2BAA2B;;AAE3B;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;EACT,aAAa;AACf;;AAEA;EACE,UAAU;EACV,iBAAiB;EACjB,gBAAgB;EAChB,yBAAyB;EACzB,mBAAmB;EACnB,aAAa;EACb,wCAAwC;EACxC,qDAAqD;EACrD,eAAe;AACjB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,iBAAiB;EACjB,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb","sourcesContent":["/* src/ui/pages/Tasks.css */\n\n.tasks-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 20px;\n  padding: 20px;\n}\n\n.task-card {\n  width: 80%;\n  max-width: 1200px;\n  min-width: 600px;\n  background-color: #f5f5f5;\n  border-radius: 12px;\n  padding: 16px;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n  transition: transform 0.2s ease, box-shadow 0.2s ease;\n  cursor: pointer;\n}\n\n.task-card:hover {\n  transform: translateY(-5px);\n}\n\n.task-topic {\n  font-size: 1.2rem;\n  color: #6a11cb;\n  margin-bottom: 8px;\n}\n\n.task-description {\n  color: #555;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
