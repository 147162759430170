import React from 'react';
import PythonCodeExecutor from '../components/PythonCodeExecutor';
import './TaskDetails.css';

const TaskDetailsPresenter = ({ task }) => {
  return (
    <div className="task-details-container">
      <h2 className="task-title">{task.topic}</h2>

      {/* Integrate the PythonCodeExecutor here */}
      <PythonCodeExecutor
        initialCode=""
        blockType="code_task"
        task={task.description}
        expectedOutput={task.expected_output}
      />
    </div>
  );
};

export default TaskDetailsPresenter;
