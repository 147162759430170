// ChatContainer.js
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import lessonsHandler from '../../data/LessonsHandler';
import ChatPresenter from './ChatPresenter';
import Modal from '../components/Modal';
import settings from '../../globals';
import useMainPresenter from './MainPresenter';
import QuestionHandler from '../../data/QuestionHandler';

const ChatContainer = () => {
  const location = useLocation();
  const { handleCloseCongratsModal } = useMainPresenter();

  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lessonLoading, setLessonLoading] = useState(false);
  const [currentLesson, setCurrentLesson] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showCongratsModal, setShowCongratsModal] = useState(false);
  const [showQuestionInput, setShowQuestionInput] = useState(false);
  const [questionText, setQuestionText] = useState('');
  const [asking, setAsking] = useState(false);
  const [error, setError] = useState(null);

  // Define default block types
  const defaultBlockTypes = ['text', 'code_example', 'code_task'];

  // Check if a lesson was passed through the location state
  const passedLesson = location.state?.lesson || null;

  // Fetch specific lesson or the current lesson
  const fetchLesson = async (lesson) => {
    setLoading(true);
    try {
      const lessonPayload = JSON.parse(lesson.payload);
      setMessages(lessonPayload);
      setCurrentLesson(lesson);
      setCurrentIndex(0);
    } catch (error) {
      console.error('Error parsing lesson payload:', error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch the current lesson from the handler
  const fetchCurrentLesson = async () => {
    setLoading(true);
    try {
      const lesson = await lessonsHandler.getCurrentLesson();
      if (lesson) {
        await fetchLesson(lesson);
      }
    } catch (error) {
      console.error('Error fetching current lesson:', error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch the next lesson based on the current lesson ID
  const fetchNextLesson = async () => {
    setLessonLoading(true);
    try {
      const nextLesson = await lessonsHandler.getNextLesson(currentLesson.id);
      if (nextLesson) {
        await fetchLesson(nextLesson);
      } else {
        // If there's no next lesson, fetch the default current lesson
        await fetchCurrentLesson();
      }
    } catch (error) {
      console.error('Error fetching next lesson:', error);
    } finally {
      setLessonLoading(false);
    }
  };

  useEffect(() => {
    if (passedLesson) {
      fetchLesson(passedLesson);
    } else {
      fetchCurrentLesson();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passedLesson]);

  useEffect(() => {
    console.log('Messages updated:', messages);
  }, [messages]);

  const handleCompleteLesson = async () => {
    if (!currentLesson) return;

    setLessonLoading(true);
    try {
      await lessonsHandler.completeLesson(currentLesson.id);

      const milestone = settings.first_milestone;
      if (currentLesson.id === milestone) {
        setShowCongratsModal(true);
      } else {
        await fetchNextLesson(); // Fetch the next lesson in sequence
      }
    } catch (error) {
      console.error('Error completing lesson:', error);
    } finally {
      setLessonLoading(false);
    }
  };

  const handleNextMessage = () => {
    setCurrentIndex((prevIndex) => prevIndex + 1);
  };

  const handleQuestionSubmit = async () => {
    if (!questionText.trim()) return;

    const lessonId = currentLesson?.id;
    const context = messages.map((message) => ({
      block_type: message.block_type,
      text: message.text,
      code_example: message.code_example,
      task: message.task,
      expected_output: message.expected_output,
      isMentorResponse: message.isMentorResponse || false,
    }));

    // Calculate current_payload_block_id by counting only default blocks up to currentIndex
    const currentPayloadBlockId = messages
      .slice(0, currentIndex + 1)
      .filter(
        (msg) =>
          defaultBlockTypes.includes(msg.block_type) &&
          !msg.isMentorResponse &&
          msg.block_type !== 'user_question'
      ).length;

    setAsking(true); // Show small loader near the "Ask" button
    setError(null); // Reset any previous errors
    try {
      const [userQuestion, responseMessage] = await QuestionHandler.sendQuestion(
        lessonId,
        questionText,
        currentPayloadBlockId,
        context
      );

      console.log(messages);

      // Inject both the question and the response into the messages array
      setMessages((prevMessages) => [
        ...prevMessages.slice(0, currentIndex + 1),
        userQuestion,
        responseMessage,
        ...prevMessages.slice(currentIndex + 1),
      ]);

      // Update currentIndex to include the newly added messages
      setCurrentIndex((prevIndex) => prevIndex + 2);

      setQuestionText(''); // Clear the input field
      setShowQuestionInput(false); // Hide the question input field
    } catch (error) {
      console.error('Error submitting question:', error);
      setError('Failed to fetch explanation. Please try again.');
    } finally {
      setAsking(false); // Hide the loader
    }
  };

  const onCloseModal = async () => {
    setShowCongratsModal(false);
    handleCloseCongratsModal();
    await fetchCurrentLesson();
  };

  return (
    <>
      {showCongratsModal && (
        <Modal
          message="Congrats! You are doing great on your journey! You can now access the menu to find previous lessons and also see additional tasks."
          onClose={onCloseModal}
        />
      )}

      <ChatPresenter
        messages={messages}
        currentIndex={currentIndex}
        handleNextMessage={handleNextMessage}
        handleCompleteLesson={handleCompleteLesson}
        loading={loading}
        lessonLoading={lessonLoading}
        showQuestionInput={showQuestionInput}
        setShowQuestionInput={setShowQuestionInput}
        questionText={questionText}
        setQuestionText={setQuestionText}
        handleQuestionSubmit={handleQuestionSubmit}
        userIsAsking={asking} // Pass asking state for the loader
      />

      {error && <div className="error-message">{error}</div>}
    </>
  );
};

export default ChatContainer;
