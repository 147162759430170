import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginPresenter from './LoginPresenter';
import { RestClient } from '../../data/RestClient';

const LoginContainer = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  // Method to handle authentication
  const handleLogin = async () => {
    setIsLoading(true); // Start loading
    try {
      const token = await RestClient.authenticate(username, password);
      if (token) {
        console.log('success');
        navigate('/app/chat'); // Redirect to chat
      } else {
        alert('Invalid credentials, please try again.');
      }
    } catch (error) {
      console.error('Error during login:', error);
      alert('Something went wrong, please try again.');
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  return (
    <LoginPresenter
      username={username}
      password={password}
      onUsernameChange={(e) => setUsername(e.target.value)}
      onPasswordChange={(e) => setPassword(e.target.value)}
      onLogin={handleLogin}
      isLoading={isLoading}
    />
  );
};

export default LoginContainer;
