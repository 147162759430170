// src/ui/pages/TaskDetailsContainer.js
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import TaskDetailsPresenter from './TaskDetailsPresenter';

const TaskDetailsContainer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const task = location.state?.task;

  if (!task) {
    return <p>Task not found.</p>;
  }

  return (
    <>
      <TaskDetailsPresenter task={task} />
    </>
  );
};

export default TaskDetailsContainer;
