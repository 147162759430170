// ChatPresenter.js
import React, { useRef, useEffect } from 'react';
import PythonCodeExecutor from '../components/PythonCodeExecutor';
import './Chat.css';

const ChatPresenter = ({
  messages,
  currentIndex,
  handleNextMessage,
  handleCompleteLesson,
  loading,
  lessonLoading,
  showQuestionInput,
  setShowQuestionInput,
  questionText,
  setQuestionText,
  handleQuestionSubmit,
  userIsAsking,
}) => {
  const chatEndRef = useRef(null);

  // Scroll to the latest message when new messages are added
  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [currentIndex, messages]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (messages.length === 0) {
    return <p>No messages available.</p>;
  }

  const isLastMessage = currentIndex === messages.length - 1;

  return (
    <div className="chat-container">
      <div className="chat-messages">
        {/* Only show messages up to the currentIndex */}
        {messages.slice(0, currentIndex + 1).map((message, index) => (
          <div
            key={index}
            className={`message-card ${message.block_type === 'user_question' ? 'user-message' : 'system-message'
              }`}
          >
            {/* Text block */}
            {message.block_type === 'text' && (
              <div className="text-message">
                {message.text.split('\n').map((line, idx) => (
                  <p key={idx}>{line}</p>
                ))}
              </div>
            )}

            {/* User question block */}
            {message.block_type === 'user_question' && (
              <div className="user-question">
                <p>{message.text}</p>
              </div>
            )}

            {/* Code example block */}
            {message.block_type === 'code_example' && (
              <div className="code-example-block">
                {/* Conditionally render the text if it exists */}
                {message.text && (
                  <div className="code-example-text">
                    {message.text.split('\n').map((line, idx) => (
                      <p key={idx}>{line}</p>
                    ))}
                  </div>
                )}
                {/* Render the code example */}
                <PythonCodeExecutor
                  blockType="code_example"
                  initialCode={message.code_example}
                />
              </div>
            )}

            {/* Code task block */}
            {message.block_type === 'code_task' && (
              <PythonCodeExecutor
                blockType="code_task"
                task={message.task}
                expectedOutput={message.expected_output}
                initialCode=""
              />
            )}

            {/* Mentor response block */}
            {message.isMentorResponse && (
              <div className="mentor-response">
                {/* Render text if available */}
                {message.text && (
                  <div className="mentor-response-text">
                    {message.text.split('\n').map((line, idx) => (
                      <p key={idx}>{line}</p>
                    ))}
                  </div>
                )}
                {/* Render the code example if available */}
                {message.code_example && (
                  <PythonCodeExecutor
                    blockType="code_example"
                    initialCode={message.code_example}
                  />
                )}
              </div>
            )}
          </div>
        ))}
        <div ref={chatEndRef} />
      </div>

      {/* Navigation Buttons */}
      <div className="chat-actions">
        {isLastMessage ? (
          <button
            className="next-lesson-button"
            onClick={handleCompleteLesson}
            disabled={lessonLoading}
          >
            {lessonLoading ? 'Loading...' : 'Next Lesson'}
          </button>
        ) : (
          <button className="next-button" onClick={handleNextMessage}>
            Next
          </button>
        )}
        <button
          className="question-button"
          onClick={() => setShowQuestionInput(!showQuestionInput)}
        >
          Have Questions?
        </button>
      </div>

      {/* Question Input Section */}
      {showQuestionInput && (
        <div className="question-input-container">
          <input
            type="text"
            placeholder="Type your question here..."
            value={questionText}
            onChange={(e) => setQuestionText(e.target.value)}
          />
          <button
            className="ask-button"
            onClick={handleQuestionSubmit}
            disabled={userIsAsking}
          >
            {userIsAsking ? 'Asking...' : 'Ask'}
          </button>
        </div>
      )}

      {/* Display error message if any */}
      {userIsAsking && null} {/* Existing error handling remains unchanged */}
    </div>
  );
};

export default ChatPresenter;
