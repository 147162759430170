// src/ui/NavigationMenu.js
import React from 'react';
import './NavigationMenu.css';
import { useLocation } from 'react-router-dom';

const NavigationMenu = ({ setActivePage }) => {
  const location = useLocation();

  const menuItems = [
    { id: 'lessons', label: 'Lessons', icon: '📚' },
    { id: 'tasks', label: 'Tasks', icon: '📝' }
  ];

  return (
    <div className="navigation-menu">
      {menuItems.map((item) => (
        <div
          key={item.id}
          className={`menu-item ${location.pathname.includes(item.id) ? 'active' : ''}`}
          onClick={() => setActivePage(item.id)}
        >
          <span className="menu-icon">{item.icon}</span>
          <span className="menu-label"> {item.label}</span>
        </div>
      ))}
    </div>
  );
};

export default NavigationMenu;
