// src/ui/pages/TasksPresenter.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Tasks.css';

const TasksPresenter = ({ tasks }) => {
  const navigate = useNavigate();

  if (!tasks || tasks.length === 0) {
    return <p>No tasks available.</p>;
  }

  const handleTaskClick = (task) => {
    // Navigate to TaskDetailsContainer and pass the task object
    navigate('/app/task-details', { state: { task } });
  };

  return (
    <div className="tasks-container">
      {tasks.map((task) => (
        <div
          key={task.id}
          className="task-card"
          onClick={() => handleTaskClick(task)}
        >
          <h3 className="task-topic">{task.topic}</h3>
          <p className="task-description">
            {task.description.length > 50
              ? `${task.description.slice(0, 70)}...`
              : task.description}
          </p>
        </div>
      ))}
    </div>
  );
};

export default TasksPresenter;
