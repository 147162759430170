/* global Sk */
import React, { useState } from 'react';
import Editor from '@monaco-editor/react';
import {RestClient,config} from '../../data/RestClient';
import './PythonCodeExecutor.css';

const PythonCodeExecutor = ({ initialCode, blockType, task, expectedOutput }) => {
  const [code, setCode] = useState(initialCode);
  const [output, setOutput] = useState('');
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState('');

  // Function to check the user's code with the backend
  // Function to check the user's code with the backend
const checkTask = async (task, userCode) => {
  setOutput(''); // Clear previous output
  setFeedback('');
  setLoading(true); // Show loader

  try {
    const payload = {
      task: task,
      user_code: userCode,
    };

    // Updated network call with the correct endpoint from config
    const response = await RestClient.post(config.CHECK_CODE_URL, payload);

    // Handle the response
    setLoading(false);

    if (response.data.is_correct) {
      setFeedback('Correct!');
      // Execute the correct code using Skulpt and show the output
      runPythonCode(userCode);
    } else {
      setFeedback('There is a mistake. Check the message below.');
      setOutput(response.data.message);
    }
  } catch (error) {
    setLoading(false);
    setFeedback('Error occurred.');
    setOutput(`Error: ${error.message}`);
  }
};


  // Function to execute Python code using Skulpt
  const runPythonCode = (userCode) => {
    const outf = (text) => {
      setOutput((prevOutput) => prevOutput + text);
    };

    const builtinRead = (x) => {
      if (Sk.builtinFiles === undefined || Sk.builtinFiles["files"][x] === undefined) {
        throw new Error(`File not found: '${x}'`);
      }
      return Sk.builtinFiles["files"][x];
    };

    Sk.configure({ output: outf, read: builtinRead, execLimit: 1000 });

    try {
      Sk.importMainWithBody('<stdin>', false, userCode);
    } catch (err) {
      setOutput(`Error: ${err.toString()}`);
    }
  };

  const handleRunCode = () => {
    setOutput('');
    setFeedback('');

    if (blockType === 'code_task') {
      checkTask(task, code);
      return;
    }

    runPythonCode(code);
  };

  return (
    <div className="python-code-executor">
      {blockType === 'code_task' && (
        <div className="task-prompt">
          <h3>Task:</h3>
          <p>{task}</p>
        </div>
      )}
      <Editor
        height="300px"
        defaultLanguage="python"
        defaultValue={code}
        onChange={(value) => setCode(value)}
        theme="vs-light"
      />
      <div className="run-section">
        <button className="run-button" onClick={handleRunCode}>
          Run
        </button>
        {loading && <span className="loader">⏳</span>}
        {feedback && <span className="feedback">{feedback}</span>}
      </div>
      <textarea
        className="output-container"
        value={output}
        readOnly
      />
    </div>
  );
};

export default PythonCodeExecutor;
