import { RestClient, config } from './RestClient';

class LessonsHandler {
  // Method to get the current lesson that hasn't been completed yet
  async getCurrentLesson() {
    try {
      // Fetch the completed lessons
      const completedResponse = await RestClient.get(config.LEARNED_LESSONS_URL);
      const completedLessons = completedResponse.data;
      console.log('Completed Lessons:', completedLessons);

      // Fetch all lessons
      const lessonsResponse = await RestClient.get(config.LESSONS_URL);
      const allLessons = lessonsResponse.data;
      console.log('All Lessons:', allLessons);

      // Get a set of completed lesson IDs for faster lookup
      const completedLessonIds = new Set(completedLessons.map((lesson) => lesson.id));

      // Find the first lesson that hasn't been completed
      const uncompletedLesson = allLessons.find(
        (lesson) => !completedLessonIds.has(lesson.id)
      );

      // If all lessons are completed, return null
      if (!uncompletedLesson) {
        console.log('All lessons have been completed.');
        return null;
      }

      console.log('Next Uncompleted Lesson:', uncompletedLesson);
      return uncompletedLesson;
    } catch (error) {
      console.error('Error fetching current lesson:', error);
      return null;
    }
  }

  async getCompletedLessons() {
    try {
      const response = await RestClient.get(config.LEARNED_LESSONS_URL);
      return response.data;
    } catch (error) {
      console.error('Error fetching completed lessons:', error);
      return [];
    }
  }

  async getAllLessons()  {
    const response = await RestClient.get(config.LESSONS_URL);
    return response.data;
  }

  async getAvailableLessons() {
    try {
      // Fetch all lessons and completed lessons
      const allLessons = await lessonsHandler.getAllLessons();
      const completedLessons = await lessonsHandler.getCompletedLessons();

      // Create a set of completed lesson IDs
      const completedIds = new Set(completedLessons.map((lesson) => lesson.id));

      // Mark lessons as completed or not
      const lessonsWithStatus = allLessons.map((lesson) => ({
        ...lesson,
        completed: completedIds.has(lesson.id)
      }));

      // Find the first uncompleted lesson
      const firstIncompleteLesson = lessonsWithStatus.find(lesson => !lesson.completed);

      // Return passed lessons + current one
      return lessonsWithStatus.filter(lesson => lesson.completed || lesson === firstIncompleteLesson);
    } catch (error) {
      console.error('Error fetching available lessons:', error);
      return [];
    }
  }

  // Method to mark a lesson as completed
  async completeLesson(lessonId) {
    try {
      const response = await RestClient.post(`${config.ADD_COMPLETED_LESSON_URL}${lessonId}/`);
      return response.data;
    } catch (error) {
      console.error('Error completing lesson:', error);
      return null;
    }
  }

  async getNextLesson(currentLessonId) {
    const allLessons = await this.getAllLessons();
    const currentIndex = allLessons.findIndex(lesson => lesson.id === currentLessonId);
  
    if (currentIndex >= 0 && currentIndex < allLessons.length - 1) {
      return allLessons[currentIndex + 1];
    }
    return null;
  }

  async getTasks () {
    const response = await RestClient.get(config.TASKS_URL);
    return response.data;
  }
}



const lessonsHandler = new LessonsHandler();
export default lessonsHandler;
