// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lessons-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
  width: 100%; /* Ensure the container spans full width */
}

.lesson-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%; /* Increase card width */
  max-width: 1200px; /* Set an upper limit for very large screens */
  min-width: 600px;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 12px;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.lesson-card:hover {
  transform: translateY(-5px);
}

.lesson-card.completed {
  background-color: #e7f9e7; /* Gentle green background for completed lessons */
}

.lesson-content {
  flex-grow: 1;
}

.tick {
  font-size: 1.5rem;
  margin-left: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/ui/pages/Lessons.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;EACT,aAAa;EACb,WAAW,EAAE,0CAA0C;AACzD;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,UAAU,EAAE,wBAAwB;EACpC,iBAAiB,EAAE,8CAA8C;EACjE,gBAAgB;EAChB,aAAa;EACb,yBAAyB;EACzB,mBAAmB;EACnB,eAAe;EACf,qDAAqD;EACrD,wCAAwC;AAC1C;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,yBAAyB,EAAE,kDAAkD;AAC/E;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;AACnB","sourcesContent":[".lessons-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 20px;\n  padding: 20px;\n  width: 100%; /* Ensure the container spans full width */\n}\n\n.lesson-card {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  width: 90%; /* Increase card width */\n  max-width: 1200px; /* Set an upper limit for very large screens */\n  min-width: 600px;\n  padding: 20px;\n  background-color: #f5f5f5;\n  border-radius: 12px;\n  cursor: pointer;\n  transition: transform 0.2s ease, box-shadow 0.2s ease;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n}\n\n.lesson-card:hover {\n  transform: translateY(-5px);\n}\n\n.lesson-card.completed {\n  background-color: #e7f9e7; /* Gentle green background for completed lessons */\n}\n\n.lesson-content {\n  flex-grow: 1;\n}\n\n.tick {\n  font-size: 1.5rem;\n  margin-left: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
