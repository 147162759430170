// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-container {
    display: flex;
    flex-direction: column;
    width: 100%; /* Ensure full width */
    height: 100vh; /* Occupy full height */
    overflow: hidden; /* Prevent unwanted scrollbars */
    background-color: #f5f5f5; /* Optional default background */
  }
  
  .content {
    flex: 1 1; /* Fills remaining space */
    display: flex;
    justify-content: center; /* Centers internal components */
    align-items: flex-start;
    overflow-y: auto; /* Allow scrolling for overflowing content */
  }
  `, "",{"version":3,"sources":["webpack://./src/ui/pages/MainContainer.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW,EAAE,sBAAsB;IACnC,aAAa,EAAE,uBAAuB;IACtC,gBAAgB,EAAE,gCAAgC;IAClD,yBAAyB,EAAE,gCAAgC;EAC7D;;EAEA;IACE,SAAO,EAAE,0BAA0B;IACnC,aAAa;IACb,uBAAuB,EAAE,gCAAgC;IACzD,uBAAuB;IACvB,gBAAgB,EAAE,4CAA4C;EAChE","sourcesContent":[".main-container {\n    display: flex;\n    flex-direction: column;\n    width: 100%; /* Ensure full width */\n    height: 100vh; /* Occupy full height */\n    overflow: hidden; /* Prevent unwanted scrollbars */\n    background-color: #f5f5f5; /* Optional default background */\n  }\n  \n  .content {\n    flex: 1; /* Fills remaining space */\n    display: flex;\n    justify-content: center; /* Centers internal components */\n    align-items: flex-start;\n    overflow-y: auto; /* Allow scrolling for overflowing content */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
