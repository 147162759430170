// src/ui/pages/LessonsContainer.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import lessonsHandler from '../../data/LessonsHandler';
import LessonsPresenter from './LessonsPresenter';

const LessonsContainer = () => {
  const [lessons, setLessons] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fetchLessons = async () => {
    setLoading(true);
    try {
      const fetchedLessons = await lessonsHandler.getAvailableLessons();
      setLessons(fetchedLessons);
    } catch (error) {
      console.error('Error fetching lessons:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLessons();
  }, []);

  const handleLessonClick = (lesson) => {
    navigate(`/app/chat`, { state: { lesson } });
  };

  return (
    <div>
      {loading ? (
        <p>Loading lessons...</p>
      ) : (
        <LessonsPresenter lessons={lessons} onLessonClick={handleLessonClick} />
      )}
    </div>
  );
};

export default LessonsContainer;
