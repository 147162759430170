import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Launcher from './ui/entry/Launcher';
import LoginContainer from './ui/entry/LoginContainer';
import MainContainer from './ui/pages/MainContainer';
import './App.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Launcher />} />
        <Route path="/login" element={<LoginContainer />} />
        {/* Main route with nested content */}
        <Route path="/app/*" element={<MainContainer />} />
      </Routes>
    </Router>
  );
}

export default App;
