import { useEffect, useState } from 'react';
import lessonsHandler from '../../data/LessonsHandler';
import { useMenu } from '../../MenuContext';
import settings from '../../globals';

const useMainPresenter = () => {
  const { showMenu, setShowMenu } = useMenu();
  const [loading, setLoading] = useState(true);
  const [currentLesson, setCurrentLesson] = useState(null);
  const [showCongratsModal, setShowCongratsModal] = useState(false);

  const milestone = settings.first_milestone;

  const checkUserProgress = async () => {
    setLoading(true);
    try {
      const completedLessons = await lessonsHandler.getCompletedLessons();
      const hasCompletedMilestone = completedLessons.some(
        (lesson) => lesson.id === milestone
      );

      console.log("we show menu?")
      console.log(hasCompletedMilestone)
      setShowMenu(hasCompletedMilestone);

      // if (hasCompletedMilestone) {
      //   const lesson = await lessonsHandler.getCurrentLesson();
      //   setCurrentLesson(lesson);
      // }
    } catch (error) {
      console.error('Error fetching user progress:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseCongratsModal = () => {
    console.log("Closing congratulatory modal...");
    checkUserProgress()
  };

  useEffect(() => {
    checkUserProgress();
  }, []);

  return {
    showMenu,
    setShowMenu,
    loading,
    currentLesson,
    showCongratsModal,
    setShowCongratsModal,
    handleCloseCongratsModal
  };
};

export default useMainPresenter;