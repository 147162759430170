import axios from 'axios';

const BASE_URL = 'https://bobby-backend.onrender.com/api/';
// const BASE_URL = 'http://127.0.0.1:8000/api/';
const config = {
  userAvatarUrl: 'https://w7.pngwing.com/pngs/340/946/png-transparent-avatar-user-computer-icons-software-developer-avatar-child-face-heroes-thumbnail.png',
  BASE_URL,
  SIGNUP_URL: `${BASE_URL}accounts/signup/`,
  USER_DETAIL_URL: `${BASE_URL}accounts/me/`,
  LOGIN_URL: `${BASE_URL}accounts/login/`,
  TOKEN_REFRESH_URL: `${BASE_URL}accounts/token/refresh/`,
  MENTORS_URL: `${BASE_URL}characters/mentors/`,
  MANAGERS_URL: `${BASE_URL}characters/managers/`,
  LESSONS_URL: `${BASE_URL}theory/lessons/`,
  ADD_COMPLETED_LESSON_URL: `${BASE_URL}theory/add_completed_lesson/`,
  LEARNED_LESSONS_URL: `${BASE_URL}theory/get_learned_lessons/`,
  MANAGER_QUESTS_URL: `${BASE_URL}characters/managers/`,
  TASKS_URL: `${BASE_URL}characters/managers/1/quests`,
  // PREVIOUS_EXPLANATION_MESSAGES_URL: `${BASE_URL}theory/get_explanation_messages/`,
  // GET_EXPLANATION_URL: `${BASE_URL}theory/get_explanation/`,
  CHECK_CODE_URL: `${BASE_URL}code_task_analysis/`,
  GET_EXPLANATION_URL_V2: `${BASE_URL}theory/get_lesson_explanation_v2/`,
};

// Utility to handle tokens
const getToken = () => localStorage.getItem('authToken');
const saveToken = (token) => localStorage.setItem('authToken', token);

const getHeaders = () => ({
  'Authorization': `Bearer ${getToken()}`,
  'Accept': 'application/json',
  'Content-Type': 'application/json',
});

class RestClient {
  static async authenticate(username, password) {
    try {
      const response = await axios.post(config.LOGIN_URL, { username, password });
      const token = response.data.access;
      console.log(token)
      if (token) {
        saveToken(token);
      }
      return token;
    } catch (error) {
      console.error('Authentication error:', error);
      return null;
    }
  }


  static async get(url) {
    return axios.get(url, { headers: getHeaders() });
  }

  static async post(url, data) {
    return axios.post(url, data, { headers: getHeaders() });
  }

  static async getWithParams(url, params) {
    return axios({
      method: 'GET',
      url: url,
      headers: getHeaders(),
      params: params,
    });
  }

  static async put(url, data) {
    return axios.put(url, data, { headers: getHeaders() });
  }

  static async delete(url) {
    return axios.delete(url, { headers: getHeaders() });
  }

  static config = {
    userAvatarUrl: 'https://w7.pngwing.com/pngs/340/946/png-transparent-avatar-user-computer-icons-software-developer-avatar-child-face-heroes-thumbnail.png',
    BASE_URL,
    SIGNUP_URL: `${BASE_URL}accounts/signup/`,
    USER_DETAIL_URL: `${BASE_URL}accounts/me/`,
    LOGIN_URL: `${BASE_URL}accounts/login/`,
    TOKEN_REFRESH_URL: `${BASE_URL}accounts/token/refresh/`,
    MENTORS_URL: `${BASE_URL}characters/mentors/`,
    MANAGERS_URL: `${BASE_URL}characters/managers/`,
    LESSONS_URL: `${BASE_URL}theory/lessons/`,
    ADD_COMPLETED_LESSON_URL: `${BASE_URL}theory/add_completed_lesson/`,
    LEARNED_LESSONS_URL: `${BASE_URL}theory/get_learned_lessons/`,
    MANAGER_QUESTS_URL: `${BASE_URL}characters/managers/`,
    PREVIOUS_EXPLANATION_MESSAGES_URL: `${BASE_URL}theory/get_explanation_messages/`,
    GET_EXPLANATION_URL: `${BASE_URL}theory/get_explanation/`,
    CHECK_CODE_URL: `${BASE_URL}code_task_analysis/`,
  };
}

export { RestClient, config };
